import React, { useState, useEffect, useContext } from "react";
import { Form, Input, Spin, DatePicker, Tooltip, Select, FormInstance } from "antd";
import { LoadingOutlined, QuestionCircleFilled } from "@ant-design/icons";
import { ToastContext } from "../../../../context/ToastContext";
import AccountsKpiConfiguration from "../../../../models/AccountsKpiConfiguration";
import Account from "../../../../models/Account";
import AccountsKpiConfigurationService from "../../../../services/crud/AccountsKpiConfigurationService";

interface Props {
  data?: AccountsKpiConfiguration;
  nonPrivateAccounts: Array<Account>;
  form: FormInstance;
}

export const AccountsKpiConfigurationForm: React.FC<Props> = ({ data, nonPrivateAccounts, form }) => {
  const id = data?.id;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const update: boolean = data?.id !== undefined;
  const { loading, loadMessage } = useContext(ToastContext);
  const [accountsKpiConfiguration, setAccountsKpiConfiguration] = useState<AccountsKpiConfiguration>(undefined);

  useEffect(() => {
    form.resetFields();
    loadMessage("Loading...", async () => {
      if (id) {
        await AccountsKpiConfigurationService.getAccountsKpiConfiguration(id).then((data) => {
          setAccountsKpiConfiguration(data.serialize());
        });
      }
    });
  }, [form, id]);
  const accountOptions = nonPrivateAccounts
  .map((a) => {
    return { value: a.id, label: `${Account.typeEmoji(a.type)} ${a.name}` };
  });

  const initialValues = {
    ...data,
    accountIds: data?.accountIds.split(',').map((a) => Number.parseInt(a)),
  };

  const content = (
    <Form initialValues={initialValues} form={form}>
      <Form.Item noStyle name="id">
        <Input type="hidden" />
      </Form.Item>
      {/* First and last name */}
      <Form.Item label="Report name" name="reportName" rules={[{ required: true }]}>
        <Input type="text" />
      </Form.Item>
      <Form.Item
        name="accountIds"
        label="Konton (företag och/eller fastigheter)"
        rules={[
          {
            required: true,
            message: "Please input ${label}!",
          },
        ]}
      >
        <Select
          mode="multiple"
          allowClear
          style={{width: '100%'}}
          options={accountOptions}
        />
      </Form.Item>
      <Form.Item label="Email addresses" name="emailAddresses" rules={[{ required: true }]}>
        <Input type="text" placeholder="namn1@example.com,namn2@example.com" />
      </Form.Item>
    </Form>
  );

  if (loading) {
    return (
      <Spin size="large" tip="Loading" indicator={<LoadingOutlined />}>
        {content}
      </Spin>
    );
  }
  return content;
}

