import { ArrowLeftOutlined, DownOutlined } from "@ant-design/icons";
import {
  Form,
  Button,
  Descriptions,
  Dropdown,
  MenuProps,
  Space,
  Table,
  Tooltip,
  Typography,
  Input,
  Alert,
  Card,
  Tabs,
  TabsProps,
  InputNumber,
} from "antd";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Account from "../../../models/Account";
import { NavigationContext } from "../../../context/NavigationContext";
import { ToastContext } from "../../../context/ToastContext";
import AccountService from "../../../services/crud/AccountService";
import DescriptionDataList from "../../../components/DescriptionDataList";
import { useForm } from "antd/lib/form/Form";
import { OverlayContext } from "../../../context/OverlayContext";
import { QuestionCircleFilled } from "@ant-design/icons/lib/icons";

import { ColumnType } from "antd/lib/table/interface";
import { EntitySelector } from "../../../components/forms/selects/EntitySelector";
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { SubscriptionsTable } from "./SubscriptionsTable";
import { PlansTable } from "./PlansTable";
import Hub from "../../../models/Hub";
import HubService from "../../../services/crud/HubService";
import { Modes } from "../../../Modes";
import UsersAccountsTable from "./UsersTable";
import { BookingsTable } from "./BookingsTable";
import DevContainer from "../../../components/DevContainer";
import InvoiceForm, { InvoiceButtonForm } from "../../../components/forms/InvoiceForm";
import { InvoiceInterface } from "../../../models/temp/Invoice";
import { UpdateInvoiceRequestPayload } from "../../../services/crud/AccountService";
import { OrganizationInterface } from "../../../models/temp/Organisation";
import { OrganizationButtonForm } from "../../../components/forms/OrganisationForm";
import { CompanyService } from "../../../services/CompanyService";
import BookingExternalService, { BookingExternal } from "../../../services/crud/BookingExternalService";
import BookingService from "../../../services/crud/BookingService";
import Booking from "../../../models/Booking";
import UserService from "../../../services/crud/UserService";
import AddHubButton from "./AddHubButton";
export const hubsState = atom<Hub[]>({
  key: "company_hubs",
  default: [],
});

export const accountState = atom<Account>({
  key: "company_account",
  default: {} as Account,
});

const Header = () => {
  let navigate = useNavigate();
  const gotoPage = (route) => {
    console.log("route is: " + route);
    navigate(route);
  };
  return (
    <div>
      <Button size="small" icon={<ArrowLeftOutlined />} type="link" onClick={() => gotoPage(-1)}>
        Back to company list
      </Button>
    </div>
  );
};

const ProfileSection = ({ account, bookings, actions = <></> }) => {
  const data = {
    id: account.id,
    name: account.name,
    "White Label": account.whiteLabel?.name ?? "No White Label",
    invited: account.authorized,
    users: account.users,
    // Account original .bookings only contain non-external bookings.
    // so override with unified bookings array in this view that contains
    // all bookings.
    bookings: bookings,
    subscriptions: account.subscriptions,
    hubs: account.hubs,
  };
  return (
    <div>
      <Card>
        <DescriptionDataList data={data} footer={<Space>{actions}</Space>} />
      </Card>
    </div>
  );
};

const EditProfileForm = ({ account, form }) => {
  return (
    <Form form={form} initialValues={account}>
      <Form.Item name="id" label="id" required>
        <Input disabled />
      </Form.Item>
      <Form.Item name="name" label="name" required>
        <Input />
      </Form.Item>
      <Tooltip title="Name of your company">
        <QuestionCircleFilled></QuestionCircleFilled>
      </Tooltip>
      <Form.Item name="whiteLabelId" label="White label">
        <EntitySelector.WhiteLabels></EntitySelector.WhiteLabels>
      </Form.Item>
    </Form>
  );
};

/**
 * This application is used to add users to an account.
 * It should check if the user is already in the account.
 * if the user is already in the account, it should not add the user and it should display a message. "User already in account"
 * @param param0
 * @returns
 */
const UserAccountFormApplication = ({ title = "Add user", accountId = "", onSuccess = () => {} }) => {
  const [form] = useForm<{ accountId: string | number; emails: string }>();
  const { loading, loadMessage } = useContext(ToastContext);
  const { openModal, closeModal } = useContext(OverlayContext);
  const initialData = { emails: "" };
  const openCreateModal = () => {
    form.resetFields();
    openModal({
      title: "Add emails to account",
      content: (
        <Form form={form} initialValues={initialData}>
          <Form.Item hidden name="id" label="id" required>
            <Input disabled />
          </Form.Item>
          <Form.Item
            name="emails"
            label="Emails"
            rules={[
              {
                required: true,
                validator(rule, value, callback) {
                  if (value && value.length > 0) {
                    const emails = value.split(",").map((email) => email.trim());
                    const valid = emails.every((email) => {
                      return email.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/);
                    });
                    if (valid) {
                      callback();
                    } else {
                      callback("Invalid email");
                    }
                  } else {
                    callback("Emails cannot be empty");
                  }
                },
              },
            ]}
          >
            <Input.TextArea placeholder={"email1@mail.com, email2@mail.com, ..."} />
          </Form.Item>

          <Tooltip title="Emails seperated by comma. For example:ec2b@example.com, email@example.com">
            <QuestionCircleFilled></QuestionCircleFilled>
          </Tooltip>
        </Form>
      ),
      onOk: () => {
        form.validateFields().then(async (values) => {
          const emails = values.emails.split(",").map((email) => email.trim());
          loadMessage("Adding emails", async () => {
            const result = await Promise.any(emails.map(async (email) => AccountService.pregrantEmailToAccount(accountId, email)));
            onSuccess();
          });
          closeModal();
        });
      },
    });
  };
  return (
    <Button type="primary" disabled={loading} onClick={openCreateModal}>
      {title}
    </Button>
  );
};

const HubsTable = ({ dataSource = undefined, id, onDeleteSelected = async (key) => {} }) => {
  const { user, mode } = useContext(NavigationContext);

  const [hubs, setHubs] = useRecoilState(hubsState);
  const [filter, setFilter] = useState("");
  const { loading, loadMessage } = useContext(ToastContext);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const navigate = useNavigate();
  const gotoPage = (route) => {
    console.log("route is: " + route);
    navigate("/companies/" + route);
  };

  function loadTable(id) {
    if (id) {
      // Load all accounts for user
      loadMessage("Loading users...", () =>
        Promise.all([HubService.list({ accountId: id })]).then((data) => {
          setHubs(data[0]);
        })
      );
    }
  }
  useEffect(() => {
    if (dataSource) setHubs(dataSource);
  }, [dataSource]);
  useEffect(() => {
    if (id) loadTable(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, id]);

  const items: MenuProps["items"] = [
    {
      label: "Remove selected from account",
      key: "REMOVE_SELECTED_FROM_ACCOUNT",
    },
  ];
  const onClick = async ({ key }) => {
    switch (key) {
      case "REMOVE_SELECTED_FROM_ACCOUNT":
        await onDeleteSelected(selectedRowKeys);

        const results = await Promise.allSettled(
          selectedRowKeys.map((hubId) => {
            return AccountService.removeHub(id, `${hubId}`).then((data) => {
              return { data, id: hubId };
            });
          })
        );
        const success = results.filter((result) => result.status === "fulfilled").map((item: any) => item.value.id);
        const failed = results.filter((result) => result.status === "rejected");
        setHubs((prev) => prev.filter((hub) => !success.find((id) => id === hub.id)));
        console.log(success);
        console.log(failed);
        break;
    }
  };

  const columnns: ColumnType<Hub>[] = [
    {
      title: "Name",
      key: "name",
      dataIndex: ["name"],
      render: (text, record, index) => {
        return <Typography.Text>{text}</Typography.Text>;
      },
      // sorter: (a, b) => a?.type?.toLowerCase().localeCompare(b?.type?.toLowerCase()),
    },
    {
      title: "Address",
      key: "address",
      dataIndex: ["address"],
      render: (text, record, index) => {
        return <Typography.Text>{text}</Typography.Text>;
      },
      // sorter: (a, b) => a?.type?.toLowerCase().localeCompare(b?.type?.toLowerCase()),
    },
  ];

  // Row selection
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  console.log(hubs);
  let actionsDropdown = <></>;
  switch (mode) {
    case Modes.SYSTEM_ADMIN:
      actionsDropdown = (
        <Dropdown menu={{ items, onClick }} trigger={["click"]}>
          <a>
            <Space>
              Actions
              <DownOutlined />
            </Space>
          </a>
        </Dropdown>
      );
      break;
    case Modes.COMPANY_ADMIN:
      actionsDropdown = (
        <Dropdown disabled menu={{}} trigger={["click"]}>
          <a>
            <Space>
              Actions
              <DownOutlined />
            </Space>
          </a>
        </Dropdown>
      );
      break;
  }
  return (
    <div>
      <Typography.Title level={3}>Hubs</Typography.Title>
      <Space style={{ marginBottom: 10, justifyContent: "space-between", width: "100%" }}>
        <Space>
          {actionsDropdown}
          <span style={{ marginLeft: 8 }}> {`(Selected ${selectedRowKeys.length} items)`} </span>
        </Space>
        <AddHubButton accountId={id}></AddHubButton>
      </Space>

      <Table
        loading={loading}
        bordered
        rowKey="id"
        dataSource={hubs}
        rowSelection={rowSelection}
        columns={columnns}
        pagination={{ total: hubs.length, defaultPageSize: 30, showSizeChanger: true }}
        // onRow={(record) => ({ onClick: () => gotoPage(record.id) })}
        // loading={loading}
      />
    </div>
  );
};

export type Props = {};
/**
 * This page lists all company accounts.
 * @param param0
 * @returns
 */
function CompanyAccountsView({}: Props) {
  const [form] = useForm();
  const { openModal, closeModal } = useContext(OverlayContext);
  const { mode } = useContext(NavigationContext);
  const { id } = useParams<any>();
  const accountId = parseInt(id);
  const [account, setAccount] = useRecoilState(accountState);
  const { loading, loadMessage } = useContext(ToastContext);
  const [bookings, setBookings] = useState<(BookingExternal | Booking)[]>(account.bookings || []);
  const users = [...(account?.users ?? [])].sort((a, b) => a.username.toLowerCase().localeCompare(b.username.toLowerCase()));
  const authorized = [...(account?.authorized || [])].sort((a, b) => a.email.toLowerCase().localeCompare(b.email.toLowerCase()));
  console.log(users);
  const loadAccount = useCallback(async () => {
    if (accountId) {
      let newAccount = await AccountService.getAccount(accountId);
      setAccount(newAccount);
      let externalBookings = await BookingExternalService.listByAccountId(accountId);
      setBookings([...externalBookings]);
    }
  }, [accountId]);

  useEffect(() => {
    loadMessage("Loading", () =>
      loadAccount()
        .then(() => "Loaded")
        .catch(() => "error loading ")
    );
  }, [accountId]);

  const handleEdit = () => {
    openEditModal();
  };
  const openEditModal = () => {
    form.resetFields();
    openModal({
      title: "Edit account profile",
      content: <EditProfileForm account={account} form={form} />,
      onOk: () => {
        form.validateFields().then((values) => {
          loadMessage("Adding item...", async () => {
            await AccountService.editAccount(account?.id, values);
            AccountService.getAccount(account?.id).then((account) => {
              setAccount(account);
            });
            return "Updated account profile";
          });
          closeModal();
        });
      },
    });
  };

  let profileActions = <></>;
  let userTableActions = { updateBalance: false };

  switch (mode) {
    case Modes.SYSTEM_ADMIN:
      profileActions = (
        <>
          <Button disabled={loading} type="primary" onClick={handleEdit}>
            Edit
          </Button>
          <></>
          <>
            <OrganizationButtonForm accountId={accountId}></OrganizationButtonForm>
          </>
        </>
      );
      userTableActions = { updateBalance: true };
      break;
    case Modes.COMPANY_ADMIN:
      profileActions = <></>;
      userTableActions = { updateBalance: false };
      break;
    case Modes.REAL_ESTATE_ADMIN:
      profileActions = <></>;
      userTableActions = { updateBalance: false };
      break;
  }
  const handleActionsComplete = () => {
    loadAccount();
  };

  const handleTabChange = (key: string) => {
    console.log(key);
    loadMessage("Loading", () =>
      loadAccount()
        .then(() => "Loaded")
        .catch(() => "error loading ")
    );
  };

  const tabItems: TabsProps["items"] = [
    {
      key: "users",
      label: `Users`,
      children: (
        <UsersAccountsTable
          dataSource={[...users, ...authorized]}
          accountId={account.id}
          onActionComplete={handleActionsComplete}
          actions={userTableActions}
          loading={loading}
        ></UsersAccountsTable>
      ),
    },
    {
      key: "bookings",
      label: `Bookings`,
      children: <BookingsTable dataSource={bookings}></BookingsTable>,
    },
    {
      key: "plans-and-subscriptions",
      label: `Plans & subscriptions`,
      children: (
        <view>
          <SubscriptionsTable id={account?.id}></SubscriptionsTable>
          <PlansTable id={account?.id}></PlansTable>
        </view>
      ),
    },
    {
      key: "hubs",
      label: `Hubs`,
      children: <HubsTable id={account?.id}></HubsTable>,
    },
    {
      key: "profile",
      label: `Company overview`,
      children: <ProfileSection actions={profileActions} bookings={bookings} account={account}></ProfileSection>,
    },
  ];

  return (
    <div>
      <Header></Header>
      <Typography.Title level={1} style={{ marginTop: "15px" }}>
        {account?.name}
      </Typography.Title>
      <Tabs type="line" style={{ minHeight: "100vh" }} defaultActiveKey="users" items={tabItems} onChange={handleTabChange} />
    </div>
  );
}

// const PlanCard: React.FC<{ plan?: Plan }> = ({ plan }) => {
//   const price = "299kr / mån";
//   const title = "EC2B - Enterprise";
//   return (
//     <Card
//       style={{ width: 300 }}
//       title={title}
//       actions={[
//         <Button type="primary" size="large">
//           Subscribe
//         </Button>,
//       ]}
//     >
//       <Typography.Paragraph>Allt i Basic</Typography.Paragraph>
//     </Card>
//   );
// };

export default CompanyAccountsView;
