import { Button, Form, Table, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { ToastContext } from "../../../../context/ToastContext";
import InfoBox from "../../../../components/InfoBox";
import Account from "../../../../models/Account";
import AccountsKpiConfigurationService from "../../../../services/crud/AccountsKpiConfigurationService";
import { ColumnsType } from "antd/lib/table";
import { listToMap } from "../../../../helpers/array-to-map";
import ReportService from "../../../../services/crud/ReportService";
import { downloadFile } from "../../../../helpers/download-file";
import { AccountsKpiConfigurationForm } from "./AccountsKpiConfigurationForm";
import AccountsKpiConfiguration from "../../../../models/AccountsKpiConfiguration";
import { OverlayContext } from "../../../../context/OverlayContext";
import { update } from "lodash";

async function exportConfig(reportName: string, accountIds: Array<number>, loadMessage) {
    loadMessage("Downloading report", async () => {
      const data = await ReportService.getAccountsKpiReport({
        reportName: reportName,
        accountIds: accountIds,
      });
      downloadFile(data.file, data.filename);
    });
}

function brIfNotLast(e, i, n) {
  return <React.Fragment>{e} {i < n && <br/>}</React.Fragment>
}

interface TableData {
  reportName: string;
  accountIdsList: number[];
  emailAddressesList: string[];
  rawData: AccountsKpiConfiguration;
}

function configToTableData(c: AccountsKpiConfiguration): TableData {
  return {
    reportName: c.reportName,
    accountIdsList: c.accountIds.split(',').map((a) => Number.parseInt(a.trim())),
    emailAddressesList: c.emailAddresses.split(',').map((e) => e.trim()),
    rawData: c,
  }
}

function nameSort(a: TableData, b: TableData) {
  return a.reportName.localeCompare(b.reportName);
}

interface Props {
  nonPrivateAccounts: Array<Account>;
}

const AccountsKpiConfigurationView: React.FC<Props> = ({nonPrivateAccounts}) => {
  const [ tableData, setTableData ] = useState(null);
  const { loadMessage, loading } = useContext(ToastContext);
  const { openModal, closeModal } = useContext(OverlayContext);
  const [form] = Form.useForm();

  const accountMap = listToMap(nonPrivateAccounts);

  const columns : ColumnsType<TableData> = [
    {
      title: 'Report name',
      dataIndex: 'reportName',
    },
    {
      title: 'Accounts',
      dataIndex: 'accountIdsList',
      render: (text, record, index) => record.accountIdsList.map((a) => {
        const account = accountMap[a];
        if (account !== null && account !== undefined) {
          return `${Account.typeEmoji(account.type)} ${account.name}`;
        }
        // fallback to id
        return a;
      }).map((e, i) => brIfNotLast(e, i, record.accountIdsList.length)),
    },
    {
      title: 'Email addresses',
      dataIndex: 'emailAddressesList',
      render: (text, record, index) => record.emailAddressesList.map((e, i) => brIfNotLast(e, i, record.emailAddressesList.length)),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record, index) => <span>
        <Button
          type="primary"
          size="small"
          onClick={ () => openFormModal(record.rawData) }
        >Edit</Button>
        <Button
          color="#ff000"
          size="small"
          onClick={ () => {
            openDeleteModal(record.rawData);
          }}
        >Delete</Button>
        <Button
          size="small"
          loading={loading && nonPrivateAccounts?.length > 0}
          onClick={ () => exportConfig(record.reportName, record.accountIdsList, loadMessage) }
        >Export now</Button>
      </span>,
    },
  ];

  useEffect(() => {
    loadMessage('Loading account KPI configs', async () => {
      const configs = await AccountsKpiConfigurationService.list();
      const data_ = configs.map(configToTableData);
      data_.sort(nameSort);
      setTableData(data_)
    });
  }, []);

  const openFormModal = (editConfig : AccountsKpiConfiguration|null = null) => {
    form.resetFields();
    openModal({
      title: editConfig === null ? "Schedule new Accounts KPI export" : "Edit scheduled export",
      content: <AccountsKpiConfigurationForm data={editConfig} form={form} nonPrivateAccounts={nonPrivateAccounts} />,
      onOk: () => {
        form.validateFields().then(async (values) => {
          values = {
            ...values,
            accountIds: values.accountIds.join(','),
          };
          console.log(values);
          const newConfig = new AccountsKpiConfiguration(values);
          console.log(newConfig);
          closeModal();
          loadMessage("Saving scheduled Accounts KPI export...", () =>
            {
              const serverFunc = editConfig === null
                ? async () => { return await AccountsKpiConfigurationService.addAccountsKpiConfiguration(newConfig); }
                : async () => { return await AccountsKpiConfigurationService.editAccountsKpiConfiguration(newConfig.id, newConfig); }
              return serverFunc()
                .then((config) => {
                  const updatedData = editConfig === null ? [
                    ...tableData,
                    configToTableData(config),
                  ] : tableData.map((c) => {
                    if (c.rawData.id == config.id) {
                      return configToTableData(config);
                    }
                    return c;
                  });
                  updatedData.sort(nameSort);
                  setTableData(updatedData);
                  return "Accounts KPI config updated";
                })
                .catch((res) => {
                  console.log(res);
                  throw Error("Could not update config");
                });
            }
          );
        });
      },
    });
  };

  const openDeleteModal = (item: AccountsKpiConfiguration) => {
    form.resetFields();
    openModal({
      title: `Delete "${item.reportName}" configuration`,
      content: <Typography.Text>Are you sure?</Typography.Text>,
      onOk: () => {
        loadMessage("Deleting item...", async () => {
          await AccountsKpiConfigurationService.deleteAccountsKpiConfiguration(item.id);
          setTableData(tableData.filter((d) => d.id !== item.id));
        });
        closeModal();
      },
    });
  };

  return (
    <>
      <h3>Scheduled exports</h3>
      <InfoBox>
        <p>Reports are built monthly at day 3 in month</p>
      </InfoBox>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px'}}>
        <div></div>
        <Button
          type="primary"
          disabled={loading || nonPrivateAccounts.length === 0}
          onClick={ () => openFormModal(null) }
        >Schedule new export</Button>
        </div>
      <Table
        columns={columns}
        dataSource={tableData}
      />
    </>
  );
};

export default AccountsKpiConfigurationView;
